<template>
  <BaseLayout title="我的资料">
    
    <div class="info">
      <van-field v-for="(item,key) in state" :key="key" :label="item.name" v-model="state[key].value" clickable input-align="right" :right-icon="state[key].icon" @click-input="state[key].icon='edit'" @blur="change(key)"></van-field>
      <van-cell title="最近登录时间" :value="profile.login_dt"/>
      <van-cell title="最近登录地址" :border="false" :value="profile.login_ip"/>
      <van-button block class="logout" type="primary" to="/logout">退出登录</van-button>
    </div>

  </BaseLayout>
</template>

<style scoped>
.info {
  margin: 10px;
  height: 100px;
  background: #F3F3F3;
  border-radius: 5px;
}
.logout {
  margin:20px 0;
}
</style>

<script>
import {reactive} from 'vue'
import {mapState, mapGetters, mapMutations} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  data() {
    const state = reactive({
      mobile: {
        name: "手机号",
        icon: "arrow",
        value: "",
      },
      username: {
        name: "用户名",
        icon: "arrow",
        value: "",
      },
      password: {
        name: "密码",
        icon: "arrow",
        value: "",
      },
      nickname: {
        name: "昵称",
        icon: "arrow",
        value: "",
      },
    });
    return {state};
  },

  created() {
    this.$init(this.reset);
  },

  computed: {
    ...mapState(["profile"]),
    ...mapGetters(["authorized", "uid", "mobile", "nickname"]),
  },

  methods: {
    reset() {
      this.state.mobile.value = this.mobile;
      this.state.username.value = this.profile.username;
      this.state.nickname.value = this.nickname;
      this.state.password.value = "******";
    },
    change(field) {
      this.state[field].icon = "arrow";
      if (field == "mobile"   && this.state.mobile.value == this.mobile) return;
      if (field == "username" && this.state.username.value == this.profile.username) return;
      if (field == "nickname" && this.state.nickname.value == this.nickname) return;
      if (field == "password" && this.state.password.value == "******") return;

      let api = "/user/change";
      let params = {what: field, value: this.state[field].value};
      if (!/(mobile|username|password)/.test(field)){
        api = "/user/modify";
        params[field] = this.state[field].value;
      }
      this.$http.post(api, params).then(() => {
        this.$init(this.reset);
      }).catch(() => {
        this.reset();
        this.state[field].icon = "edit";
      });
    },
  }
}
</script>
